import React, { useEffect, useState } from 'react';

import { AiFillAppstore, AiOutlinePlus } from 'react-icons/ai';
import { useQuery } from 'react-query';
import { NumberParam, useQueryParam } from 'use-query-params';

import { getTotals } from 'src/api/incomes';
import { getTaxIncomeSummary } from 'src/api/users';
import { useYear } from 'src/hooks';
import {
  Toastify,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
  Button,
  observer,
} from 'src/modules';
import store from 'src/stores';
import { formatCurrency } from 'src/utils';

import TabDividendsInterest from '../components/TabDividendsInterest';
import IncomesTabTotals from '../IncomesTab/IncomesTabTotals';
import { TaxType } from '../IncomesTab/styles';

import AddPAYE from './Components/AddPAYE';
import AddSelfEmployment from './Components/AddSelfEmployment';
import ExpensesTabTotals from './Components/ExpensesTabTotals';
import TabAll from './Components/TabAll';
import TabPAYE from './Components/TabPAYE';
import TabSelfEmployed from './Components/TabSelfEmployed';
import {
  Container,
  Tabs,
  Tab,
  TabsHeader,
  TabDetails,
  NewItemButton,
  Select,
  TabsHeaderContent,
  TotalsContainer,
} from './styles';

export const handleStatusColor = (status: string) => {
  if (
    status === 'expense_marked_details_without_receipt' ||
    status === 'expense_marked'
  )
    return '#ccc';
  else if (
    status === 'expense_marked_details_with_receipt' ||
    status === 'expense_marked_manual_mileage'
  )
    return '#209782';
  else if (
    status === 'income_reconciled_without_invoice' ||
    status === 'income_reconciled_with_invoice'
  )
    return '#209782';
};

export const checkGrossColor = (incomes, expenses, setGrossColor) => {
  if (incomes && expenses) {
    const expensePercentage =
      (expenses.gross.reconciled / incomes.gross.reconciled) * 100;

    if (expensePercentage > 30) {
      setGrossColor('red');
    } else if (expensePercentage >= 20 && expensePercentage <= 30) {
      setGrossColor('orange');
    } else {
      setGrossColor('#209782');
    }
  }
};

function ExpensesTab() {
  const expenses = store.expenses;

  const [userId] = useQueryParam('id', NumberParam);
  const [year] = useYear();

  const [activeTab, setActiveTab] = useState('All');

  const [showAddPayePage, setShowAddPayePage] = useState(false);
  const [showAddSelfEmployedPage, setShowAddSelfEmployedPage] = useState(false);
  const [showAddCisPage, setShowAddCisPage] = useState(false);
  const [showAddPropertyPage, setShowAddPropertyPage] = useState(false);
  const [tabIndexClicked, setTabIndexClicked] = useState(0);
  const [grossColor, setGrossColor] = useState('#333');

  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);

  const [incomeTypeSelected, setIncomeTypeSelected] = useState(false);

  const {
    data: taxIncomeSummary,
    isLoading: isLoadingTaxIncomeSummary,
    refetch,
  } = useQuery(
    'getTaxIncomeSummary',
    () =>
      userId
        ? getTaxIncomeSummary(userId, year)
        : Promise.reject('User ID is undefined'),
    {
      onError: ({ response }) => {
        Toastify.toast.error(response.data.message);
      },
    },
  );

  const {
    data: dataTotals,
    isLoading: isLoadingTotals,
    refetch: refetchExpensesTotals,
  } = useQuery('getExpensesTotals', () => getTotals(userId, year), {
    onError: ({ response }) => {
      Toastify.toast.error(response.data.message);
    },
  });

  useEffect(() => {
    expenses.setRefetchSummary(refetch);
    expenses.setRefetchTotals(refetchExpensesTotals);
  }, []);

  useEffect(() => {
    if (!isLoadingTotals) {
      checkGrossColor(
        dataTotals.total_incomes,
        dataTotals.total_expenses,
        setGrossColor,
      );
    }
  }, [isLoadingTotals]);

  const onTabChange = (tabName: string, index: number) => {
    setIncomeTypeSelected(false);
    setActiveTab(tabName);

    if (index || index === 0) {
      setTabIndexClicked(index);
    }
  };

  const onSelectIncomeType = (incomeType: string) => {
    setIncomeTypeSelected(true);

    if (incomeType === 'employment') {
      setShowAddPayePage(true);
      setShowAddCisPage(false);
      setShowAddPropertyPage(false);
      setShowAddSelfEmployedPage(false);
      setModal(false);
    } else if (incomeType === 'self_employed') {
      setShowAddSelfEmployedPage(true);
      setShowAddPayePage(false);
      setShowAddCisPage(false);
      setShowAddPropertyPage(false);
      setModal(false);
    }
  };

  return (
    <Container>
      <TabsHeader>
        <TabsHeaderContent>
          {!isLoadingTaxIncomeSummary &&
            taxIncomeSummary.map((item, index) => {
              return (
                <Tab
                  key={index}
                  onClick={() => onTabChange(item.title, index)}
                  style={{
                    border:
                      index === tabIndexClicked
                        ? '1px solid #57e4cd'
                        : 'initial',
                  }}
                >
                  {item.title !== 'All' ||
                    (item.tax_value < 1 && (
                      <AiFillAppstore color="#57E4CD" size={26} />
                    ))}

                  <TabDetails>
                    <span className="title">
                      {item.title === 'Employment' ? 'PAYE' : item.title}
                    </span>
                    <span className="value">{formatCurrency(item.value)}</span>

                    {item.tax_value > 0 && (
                      <>
                        <TaxType taxType={item.tax_type}>
                          {item.tax_type}
                        </TaxType>
                        <TaxType taxType={item.tax_type}>
                          {item.tax_value &&
                            formatCurrency(item.tax_value.toFixed(2))}
                        </TaxType>
                      </>
                    )}
                  </TabDetails>
                </Tab>
              );
            })}

          <NewItemButton onClick={() => setModal(true)}>
            <AiOutlinePlus size={18} />
          </NewItemButton>
        </TabsHeaderContent>

        {isLoadingTotals && <span>Loading...</span>}

        <TotalsContainer>
          {!isLoadingTotals && (
            <div>
              <IncomesTabTotals totals={dataTotals} />
            </div>
          )}

          {!isLoadingTotals && (
            <div>
              <ExpensesTabTotals totals={dataTotals} grossColor={grossColor} />
            </div>
          )}
        </TotalsContainer>
      </TabsHeader>
      <Tabs>
        {activeTab === 'All' && !incomeTypeSelected && (
          <div title="Tab All">
            <TabAll />
          </div>
        )}

        {activeTab === 'Employment' &&
          /* !showAddPayePage && */
          !incomeTypeSelected && (
            <div onClick={() => onTabChange('Employment')} title="Tab PAYE">
              <TabPAYE />
            </div>
          )}

        {showAddPayePage && (
          <AddPAYE
            setShowAddPayePage={setShowAddPayePage}
            setIncomeTypeSelected={setIncomeTypeSelected}
          />
        )}

        {activeTab === 'Self Employed' &&
          /* !showAddSelfEmployedPage && */
          !incomeTypeSelected && (
            <div
              onClick={() => onTabChange('Self Employed')}
              title="Tab Self Employed"
            >
              <TabSelfEmployed />
            </div>
          )}

        {activeTab === 'Dividends Interest' &&
          /* !showAddSelfEmployedPage && */
          !incomeTypeSelected && (
            <div
              onClick={() => onTabChange('Dividends Interest')}
              title="Tab Dividends Interest"
            >
              <TabDividendsInterest />
            </div>
          )}
        {showAddSelfEmployedPage && (
          <AddSelfEmployment
            setShowAddSelfEmployedPage={setShowAddSelfEmployedPage}
            setIncomeTypeSelected={setIncomeTypeSelected}
          />
        )}
      </Tabs>

      <Modal centered isOpen={modal} toggle={modalToggle}>
        <ModalHeader toggle={modalToggle}>Expense Type</ModalHeader>
        <ModalBody
          className="d-flex flex-wrap"
          style={{ flexDirection: 'column', justifyContent: 'center' }}
        >
          <Select onChange={(e) => onSelectIncomeType(e.target.value)}>
            <option value="">Select an option</option>
            <option value="employment">PAYE</option>
            <option value="self_employed">Self Employed</option>
          </Select>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={modalToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}

export default observer(ExpensesTab);
